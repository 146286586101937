<template>
    <div class="container-fluid">
        <div class="block-header">
            <bread-crumb></bread-crumb>
        </div>
        <div class="channel-main">
            <!-- Channel Sidebar -->
            <div class="channel-left">
                <div class="card h-100">
                    <div class="body">
                        <!-- Business Model -->
                        <div class="add-business mb-3">
                            <!-- Add Business Channel Button-->
                            <b-button variant="primary w-100" size="lg" v-b-modal.add_channel>Add Business Channel</b-button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Channel Right Body -->
            <div class="channel-right">
                <div class="card h-100">
                    <div class="d-flex justify-content-center align-items-center align-middle">
                        <h5 class="font-weight-normal text-muted mb-0 mt-5">No Channels Added</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'BlankChannelsComponent',
    components: {
        BreadCrumb : () => import('@/components/BreadCrumb.vue'),
    }
}
</script>